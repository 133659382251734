import { Card, CardActions, CardContent, Checkbox, Fab, FormControlLabel, Grid, makeStyles, Theme } from '@material-ui/core';
import PlayIcon from '@material-ui/icons/PlayCircleOutline';
import * as React from 'react';
import { MouseEvent, useEffect, useState } from 'react';
import { ServiceWorkerState } from '../../shared/Hooks/useServiceWorker';
import Exam, { IExcersise } from './Exam';

const useStyles = makeStyles((theme: Theme) => ({
  cardContent: {
    '& > label': {
      width: 90,
    },
  },
  content: {
    marginTop: theme.spacing(2),
  },
  root: {
    maxWidth: 640,
    transformOrigin: '50% 0%',
    transition: 'transform 0.25s',
    [theme.breakpoints.up('lg')]: {
      transform: 'scale(2)',
    },
  }
}));

function getAdditionExcercises(maxNumber: number)
{
  const result: IExcersise[] = [];
  for(let a = 1; a < maxNumber; a++)
  {
    for(let b = a; b <= maxNumber - a; b++)
    {
      const c = a + b;
      const _ = '?';
      result.push({ answer: a, question: `${_} + ${b} = ${c}`, });
      result.push({ answer: b, question: `${a} + ${_} = ${c}`, });
      result.push({ answer: c, question: `${a} + ${b} = ${_}`, });
      result.push({ answer: a, question: `${c} - ${b} = ${_}`, });
      result.push({ answer: b, question: `${c} - ${_} = ${a}`, });
      result.push({ answer: c, question: `${_} - ${b} = ${a}`, });
    }
  }
  return result;
}

function getMultiplicationExcercises(multiplier: number)
{
  const result: IExcersise[] = [];
  const b = multiplier;
  for(let a = 2; a <= 10; a++)
  {
    const c = a * b;
    const _ = '?';
    result.push({ answer: a, question: `${_} × ${b} = ${c}`, });
    result.push({ answer: a, question: `${b} × ${_} = ${c}`, });
    result.push({ answer: c, question: `${a} × ${b} = ${_}`, });
    result.push({ answer: c, question: `${b} × ${a} = ${_}`, });
    result.push({ answer: a, question: `${c} ÷ ${b} = ${_}`, });
    result.push({ answer: a, question: `${c} ÷ ${_} = ${b}`, });
    result.push({ answer: c, question: `${_} ÷ ${b} = ${a}`, });
    result.push({ answer: c, question: `${_} ÷ ${a} = ${b}`, });
  }
  return result;
}

export default function Component(props: {
  serviceWorkerState: ServiceWorkerState,
  env: string
})
{
  useEffect(() =>
  {
    if(props.serviceWorkerState === ServiceWorkerState.UpdateAvailable)
    {
      (async () =>
      {
        const registration = await navigator.serviceWorker.ready;
        await registration.unregister();
        window.location.reload();
      })();
    }
  }, [props.serviceWorkerState]);

  const [checked, setChecked] = useState<number[]>([]);
  const [runExam, setRunExam] = useState(false);
  const [excersises, setExcersises] = useState<IExcersise[]>([]);
  const [maxNumber, setMaxNumber] = useState<number>(0);

  const courses = [
    {
      getExcercises: () => getAdditionExcercises(10),
      maxNumber: 10,
      title: "+- 10",
    },
    {
      getExcercises: () => getAdditionExcercises(20),
      maxNumber: 20,
      title: "+- 20",
    },
    {
      getExcercises: () => getAdditionExcercises(30),
      maxNumber: 30,
      title: "+- 30",
    },
    {
      getExcercises: () => getAdditionExcercises(40),
      maxNumber: 40,
      title: "+- 40",
    },
    {
      getExcercises: () => getAdditionExcercises(50),
      maxNumber: 50,
      title: "+- 50",
    },
    {
      getExcercises: () => getAdditionExcercises(60),
      maxNumber: 60,
      title: "+- 60",
    },
    {
      getExcercises: () => getAdditionExcercises(70),
      maxNumber: 70,
      title: "+- 70",
    },
    {
      getExcercises: () => getAdditionExcercises(80),
      maxNumber: 80,
      title: "+- 80",
    },
    {
      getExcercises: () => getAdditionExcercises(90),
      maxNumber: 90,
      title: "+- 90",
    },
    {
      getExcercises: () => getAdditionExcercises(100),
      maxNumber: 100,
      title: "+- 100",
    },
    {
      getExcercises: () => getMultiplicationExcercises(2),
      maxNumber: 20,
      title: "×÷ 2",
    },
    {
      getExcercises: () => getMultiplicationExcercises(3),
      maxNumber: 30,
      title: "×÷ 3",
    },
    {
      getExcercises: () => getMultiplicationExcercises(4),
      maxNumber: 40,
      title: "×÷ 4",
    },
    {
      getExcercises: () => getMultiplicationExcercises(5),
      maxNumber: 50,
      title: "×÷ 5",
    },
    {
      getExcercises: () => getMultiplicationExcercises(6),
      maxNumber: 60,
      title: "×÷ 6",
    },
    {
      getExcercises: () => getMultiplicationExcercises(7),
      maxNumber: 70,
      title: "×÷ 7",
    },
    {
      getExcercises: () => getMultiplicationExcercises(8),
      maxNumber: 80,
      title: "×÷ 8",
    },
    {
      getExcercises: () => getMultiplicationExcercises(9),
      maxNumber: 90,
      title: "×÷ 9",
    },
  ];

  const toggle = (e: MouseEvent<HTMLElement>) =>
  {
    e.preventDefault();
    const value = parseInt(e.currentTarget.attributes.getNamedItem('data-index')?.value ?? "0", 10);
    const currentIndex = checked.indexOf(value)
    const newChecked = [...checked];

    if(currentIndex === -1)
    {
      newChecked.push(value);
    }
    else
    {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  }

  const playClicked = () =>
  {
    const newExcercises: IExcersise[] = [];
    let newMaxNumber = 0;
    for(const i of checked)
    {
      newExcercises.splice(newExcercises.length - 1, 0, ...courses[i].getExcercises())
      newMaxNumber = Math.max(newMaxNumber, courses[i].maxNumber);
    }
    setExcersises(newExcercises);
    setMaxNumber(newMaxNumber);
    setRunExam(true);
  }

  const onClose = () =>
  {
    setRunExam(false);
  }

  const classes = useStyles();
  return (
    <Grid direction="row" container justify="center" className={classes.content} >
      {runExam ? <Exam excercises={excersises} maxNumber={maxNumber} onClose={onClose} /> :
        <Grid item xs={11} className={classes.root}>
          <Card>
            <CardContent className={classes.cardContent}>
              {courses.map((c, i) => (
                <FormControlLabel
                  control={<Checkbox color="primary" checked={checked.indexOf(i) !== -1} tabIndex={-1} disableRipple onClick={toggle} data-index={i} />}
                  label={c.title}
                />
              ))}
            </CardContent>
            <CardActions>
              <Fab variant="extended" size="medium" color="primary" disabled={checked.length === 0} onClick={playClicked} ><PlayIcon />Start</Fab>
            </CardActions>
          </Card>
        </Grid>}
    </Grid>
  );
}
