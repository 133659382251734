import { Button, Grid, IconButton, makeStyles, Theme, Typography } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/HighlightOff';
import * as React from 'react';
import { FunctionComponent, MouseEvent } from 'react';

const useStyles = makeStyles((theme: Theme) => ({
  content: {
    marginTop: theme.spacing(0),
  },
  header: {
    '& > ul': {
      '& > li:nth-child(1)': {
        marginLeft: theme.spacing(1.5),
        padding: theme.spacing(1.5),
      },
      '& > li:nth-child(2)': {
        marginTop: theme.spacing(0.5),
      },
      '& > li:nth-child(3)': {
        marginLeft: 'auto',
        marginRight: 12,
      },
      display: 'grid',
      gridTemplateColumns: '1fr repeat(1, auto) 1fr',
      listStyle: 'none',
      margin: 0,
      padding: 0,
      width: '100%',
    },
    width: '100%',
  },
}));

interface IExerciseProps
{
  onAnswer: (answer: number) => void,
  maxNumber: number,
  onClose: () => void,
  question: string
}

const Component: FunctionComponent<IExerciseProps> = (props) =>
{
  const getNumbers = () =>
  {
    const result: number[] = [];
    for(let i = 0; i < props.maxNumber; i++)
    {
      result.push(i + 1);
    }
    return result;
  }

  const buttonClick = (e: MouseEvent<HTMLButtonElement>) =>
  {
    e.preventDefault();
    if(props.onAnswer)
    {
      props.onAnswer(parseInt(e.currentTarget.attributes.getNamedItem('data-answer')?.value ?? "0", 10));
    }
  }

  const classes = useStyles();
  return (
    <Grid direction="column" container alignItems="center" justify="center" spacing={3} className={classes.content}>
      <Grid item xs={12} className={classes.header}>
        <ul>
          <li>
            {props.children}
          </li>
          <li><Typography variant="h4">{props.question}</Typography></li>
          <li><IconButton onClick={props.onClose}><CloseIcon /></IconButton></li>
        </ul>
      </Grid>
      {props.maxNumber > 0 ?
        <Grid item xs={12}>
          {
            getNumbers().map(v =>
              (<Button key={v} onClick={buttonClick} data-answer={v} >{v}</Button>))
          }
        </Grid>
        : null}
    </Grid>
  );
}

export default Component;
