import { CssBaseline } from '@material-ui/core';
import { blue, cyan, grey, pink } from '@material-ui/core/colors';
import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import React, { FunctionComponent } from 'react';
import ReactDOM from 'react-dom';
import 'typeface-roboto-multilang/latin-ext.css';
import App from './App';
import usePaletteType from './shared/Hooks/usePaletteType';

export interface AppConfig
{
  uri: string,
  env: string
}

const Index: FunctionComponent<{ appConfig: AppConfig }> = (props) =>
{
  const paletteType = usePaletteType();

  // ««·· palette
  const palettes = {
    background: {
      dark: {
        default: '#303030',
      },
      light: {
        default: grey[300],
      },
    },
    primary: {
      dark: {
        contrastText: grey[900],
        main: blue[500],
      },
      light: {
        contrastText: 'white',
        main: blue[500],
      },
    },
    secondary: {
      dark: cyan,
      light: { main: pink[300] },
    },
    text: {
      primary: {
        dark: grey[300],
        light: '#000',
      },
    },
  }
  // ··»»

  // ««·· theme
  const theme = createMuiTheme({
    palette: {
      background: {
        default: palettes.background[paletteType].default,
      },
      primary: palettes.primary[paletteType],
      secondary: palettes.secondary[paletteType],
      text: {
        primary: palettes.text.primary[paletteType],
      },
      type: paletteType,
    },
    typography: {
    },
  });
  // ··»»

  
  return (
    <React.StrictMode>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <App appConfig={props.appConfig} />
        </ThemeProvider>
    </React.StrictMode>
  );
}

async function main()
{
  const response = await fetch('/app.config.json');
  const config = await response.json() as AppConfig;
  ReactDOM.render(<Index appConfig={config} />, document.getElementById('root'));
}

main();
