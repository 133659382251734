import { Badge, Grid, Paper, Theme } from '@material-ui/core';
import ThumbUpIcon from '@material-ui/icons/ThumbUpOutlined';
import { makeStyles } from '@material-ui/styles';
import * as React from 'react';
import { FunctionComponent, useEffect, useState } from 'react';
import Exercise from './Exercise';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    maxWidth: 640,
    transformOrigin: '50% 0%',
    transition: 'transform 0.25s',
    [theme.breakpoints.up('lg')]: {
      transform: 'scale(2)',
    },
  }
}));

export interface IExcersise
{
  answer: number;
  question: string;
}

const Component: FunctionComponent<{ excercises: IExcersise[], maxNumber: number, onClose: () => void }> = (props) =>
{
  const [question, setQuestion] = useState("");
  const [expectedAnswer, setExpectedAnswer] = useState(0);
  const [rightAnswers, setRightAnswers] = useState(0);
  const [maxAnswers] = useState(props.excercises.length);
  const [maxNumber, setMaxNumber] = useState(props.maxNumber);
  const [excercises] = useState(props.excercises);
  const [startedAt] = useState(new Date());

  useEffect(() =>
  {
    nextExcercise();
    // eslint-disable-next-line
  }, []);

  const onAnswer = (answer: number) =>
  {
    if(expectedAnswer === answer)
    {
      setRightAnswers(rightAnswers + 1);
    }
    nextExcercise();
  }

  function random(max: number): number
  {
    return Math.round(Math.random() * (max - 1)) + 1;
  }

  const nextExcercise = () =>
  {
    const now = new Date();
    const passedMinutes = (now.getTime() - startedAt.getTime()) / (1000 * 60);
    if(excercises.length === 0 || passedMinutes > 3)
    {
      setQuestion("Gratulálok!");
      setExpectedAnswer(0);
      setMaxNumber(0);
    }
    else
    {
      let i = random(excercises.length - 1);
      if(i > excercises.length - 1) { i = excercises.length - 1 }
      setQuestion(excercises[i].question);
      setExpectedAnswer(excercises[i].answer);
      excercises.splice(i, 1);
    }
  }

  const classes = useStyles();
  return (
    <Grid item xs={11} className={classes.root}>
      <Paper>
        <Exercise question={question} maxNumber={maxNumber} onAnswer={onAnswer} onClose={props.onClose}>
          <Badge color="primary" badgeContent={rightAnswers} max={maxAnswers}>
            <ThumbUpIcon color="primary" fontSize='default' />
          </Badge>
        </Exercise>
      </Paper>
    </Grid>
  );
}

export default Component;
